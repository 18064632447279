/**
 * News Component - Neuigkeiten und Ankündigungen
 * 
 * Diese Komponente zeigt aktuelle Nachrichten und Ankündigungen des Vereins.
 * Sie bietet eine chronologisch sortierte Übersicht aller News-Einträge.
 * 
 * Contentful Integration:
 * - Content Type: 'news'
 * - Felder:
 *   - titel: Überschrift der Nachricht
 *   - datum: Veröffentlichungsdatum
 *   - inhalt: Rich-Text Inhalt
 *   - bild: Optionales Hauptbild
 *   - kategorie: Kategorisierung der Nachricht
 * 
 * Features:
 * - Sortierung nach Datum (neueste zuerst)
 * - Rich-Text Rendering mit @contentful/rich-text-react-renderer
 * - Responsive Layout für alle Bildschirmgrößen
 * - Kategoriefilter (falls implementiert)
 * - Pagination/Lazy Loading für bessere Performance
 */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Badge, Modal } from 'react-bootstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import client from '../config/contentful';
import './News.css';
import './Modal.css';

const ITEMS_PER_PAGE = 4; // Zeige 4 Neuigkeiten pro Seite

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // Konvertiere den gesamten Inhalt des Paragraphen in einen String
      const textContent = node.content
        .map(content => {
          if (content.marks && content.marks.some(mark => mark.type === 'bold')) {
            return `<strong>${content.value}</strong>`;
          }
          return content.value;
        })
        .join('');

      // Teile den Text an Zeilenumbrüchen und rendere jede Zeile
      return (
        <p>
          {textContent.split('\n').map((text, i, array) => (
            <React.Fragment key={i}>
              {text.includes('<strong>') ? (
                <span dangerouslySetInnerHTML={{ __html: text }} />
              ) : (
                text
              )}
              {i < array.length - 1 && <br />}
            </React.Fragment>
          ))}
        </p>
      );
    }
  }
};

const NewsCard = ({ item, onReadMore }) => (
  <Card className="h-100 shadow-sm">
    <Card.Body>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <Badge bg="success">{item.fields.kategorie || 'Allgemein'}</Badge>
        <small className="text-muted">{new Date(item.fields.datum).toLocaleDateString()}</small>
      </div>
      <Card.Title>{item.fields.titel}</Card.Title>
      <Card.Text className="text-truncate">{item.fields.kurzbeschreibung}</Card.Text>
      <button className="btn btn-link p-0" onClick={() => onReadMore(item)}>
        Weiterlesen...
      </button>
    </Card.Body>
  </Card>
);

const News = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedNews, setSelectedNews] = useState(null);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        console.log('Fetching news with client:', client);
        const response = await client.getEntries({
          content_type: 'news',
          order: '-fields.datum',
          limit: ITEMS_PER_PAGE * page
        });
        console.log('Contentful response:', response);
        setNews(response.items);
        setTotalItems(response.total);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching news:', err);
        setError(`Fehler beim Laden der Neuigkeiten: ${err.message}`);
        setLoading(false);
      }
    };

    fetchNews();
  }, [page]);

  if (loading) return (
    <Container className="my-4">
      <div className="text-center">Laden...</div>
    </Container>
  );

  if (error) return (
    <Container className="my-4">
      <div className="alert alert-danger">{error}</div>
    </Container>
  );

  return (
    <Container className="my-4">
      <h2 className="news-heading mb-4">Neuigkeiten</h2>
      {news.length === 0 ? (
        <div className="alert alert-info">Keine Neuigkeiten verfügbar.</div>
      ) : (
        <>
          <Row xs={1} md={2} className="g-4">
            {news.map((item) => (
              <Col key={item.sys.id}>
                <NewsCard item={item} onReadMore={setSelectedNews} />
              </Col>
            ))}
          </Row>

          {totalItems > ITEMS_PER_PAGE * page && news.length >= ITEMS_PER_PAGE && (
            <div className="text-center mt-4">
              <button
                className="btn btn-outline-success"
                onClick={() => setPage(p => p + 1)}
                style={{ 
                  borderColor: '#198754',
                  color: '#198754',
                  transition: 'all 0.3s'
                }}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = '#198754';
                  e.target.style.color = 'white';
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = 'transparent';
                  e.target.style.color = '#198754';
                }}
              >
                Mehr laden
              </button>
            </div>
          )}
        </>
      )}

      <Modal
        show={!!selectedNews}
        onHide={() => setSelectedNews(null)}
        size="lg"
        centered
      >
        {selectedNews && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{selectedNews.fields.titel}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-between mb-3">
                <Badge bg="success">{selectedNews.fields.kategorie || 'Allgemein'}</Badge>
                <small className="text-muted">
                  {new Date(selectedNews.fields.datum).toLocaleDateString()}
                </small>
              </div>
              <Row>
                <Col md={7}>
                  {selectedNews.fields.inhalt && 
                    documentToReactComponents(selectedNews.fields.inhalt, renderOptions)
                  }
                </Col>
                <Col md={5}>
                  {selectedNews.fields.bild && (
                    <div className="sport-image-container mb-3">
                      <img
                        src={selectedNews.fields.bild.fields.file.url}
                        alt={selectedNews.fields.titel}
                        className="img-fluid shadow-sm sport-image"
                      />
                    </div>
                  )}
                  {selectedNews.fields.bild2 && (
                    <div className="sport-image-container mb-3">
                      <img
                        src={selectedNews.fields.bild2.fields.file.url}
                        alt={`${selectedNews.fields.titel} - Bild 2`}
                        className="img-fluid shadow-sm sport-image"
                      />
                    </div>
                  )}
                  {selectedNews.fields.bild3 && (
                    <div className="sport-image-container mb-3">
                      <img
                        src={selectedNews.fields.bild3.fields.file.url}
                        alt={`${selectedNews.fields.titel} - Bild 3`}
                        className="img-fluid shadow-sm sport-image"
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Modal.Body>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default News;
