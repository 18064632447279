import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaEnvelope, FaPhone } from 'react-icons/fa';
import client from '../config/contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './Footer.css';

const Footer = () => {
  const [footerData, setFooterData] = useState(null);
  const currentYear = new Date().getFullYear();
  
  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'footer'
        });
        
        const fields = response.items[0]?.fields;
        setFooterData(fields);
      } catch (error) {
        console.error('Error fetching footer data:', error);
      }
    };

    fetchFooterData();
  }, []);

  const handleLegalClick = () => {
    setTimeout(() => {
      const heading = document.querySelector('.legal-heading');
      if (heading) {
        const offset = 20; // 20 pixels offset from the top
        const elementPosition = heading.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }, 100);
  };

  const renderOptions = {
    renderNode: {
      paragraph: (node, children) => <p className="mb-0">{children}</p>
    }
  };

  return (
    <footer className="footer">
      <Container>
        <Row className="mb-4">
          <Col md={6} className="mb-3 mb-md-0">
            <h5>Kontakt</h5>
            {footerData?.telefon && (
              <p><FaPhone className="me-2" />{footerData.telefon}</p>
            )}
            {footerData?.mail && (
              <p><FaEnvelope className="me-2" />{footerData.mail}</p>
            )}
            <div className="social-icons">
              <a 
                href={footerData?.facebook || "https://www.facebook.com/profile.php?id=61573875882583"} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="me-3" 
                aria-label="Besuchen Sie uns auf Facebook"
              >
                <FaFacebook size={24} />
              </a>
              <a 
                href={footerData?.instagram || "https://www.instagram.com/tus_jaegersfreude1888e.v"} 
                target="_blank" 
                rel="noopener noreferrer" 
                aria-label="Folgen Sie uns auf Instagram"
              >
                <FaInstagram size={24} />
              </a>
            </div>
          </Col>
          <Col md={6}>
            <h5>Adresse</h5>
            {footerData?.adresse && (
              <div className="footer-address">
                {documentToReactComponents(footerData.adresse, renderOptions)}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <hr className="my-2" style={{ borderColor: '#198754' }} />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4, offset: 4 }} className="text-center">
            <p className="text-muted small mb-0">
              &copy; {currentYear} TuS 1888 Jägersfreude e.V. Alle Rechte vorbehalten.{' '}
              <Link to="/impressum" className="text-muted">Impressum</Link>{' '}
              <Link to="/rechtliches" className="text-muted" onClick={handleLegalClick}>Rechtliche Hinweise</Link>
            </p>
          </Col>
          <Col md={4} className="text-end">
            <p className="text-muted small">
              Webdesign &copy; {currentYear} Swen Engel
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
