import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import client from '../config/contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './Verein.css';

const Verein = () => {
  const [content, setContent] = useState({
    vereinsdaten: null,
    geschichte: null,
    vorstand: []
  });

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const [geschichteResponse, vorstandResponse, vereinsdatenResponse] = await Promise.all([
          client.getEntries({
            content_type: 'geschichte'
          }),
          client.getEntries({
            content_type: 'vorstand',
            order: 'sys.createdAt'
          }),
          client.getEntries({
            content_type: 'vereinsdaten'
          })
        ]);

        setContent({
          geschichte: geschichteResponse.items[0]?.fields || null,
          vorstand: vorstandResponse.items || [],
          vereinsdaten: vereinsdatenResponse.items[0]?.fields || null
        });
      } catch (error) {
        console.error('Error fetching verein content:', error);
      }
    };

    fetchContent();
  }, []);

  const getInitials = (name) => name.split(' ').map(part => part[0]).join('');
  const getImageUrl = (url) => url?.startsWith('//') ? `https:${url}` : url;

  const renderVorstandCard = (member) => (
    <Col md={3} className="mb-4" key={member.sys.id}>
      <Card className="h-100 shadow-sm text-center">
        <Card.Body>
          {member.fields.profilbild ? (
            <div className="profile-image-wrapper mb-3">
              <img
                src={getImageUrl(member.fields.profilbild.fields.file.url)}
                alt={`${member.fields.name} Profilbild`}
                className="profile-image"
              />
            </div>
          ) : (
            <div className="icon-circle mb-3">
              <span className="text-muted fs-1">{getInitials(member.fields.name)}</span>
            </div>
          )}
          <Card.Title>{member.fields.name}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{member.fields.funktion}</Card.Subtitle>
        </Card.Body>
      </Card>
    </Col>
  );

  const renderVereinsdaten = () => (
    <Card className="shadow-sm">
      <Card.Header as="h5">Vereinsdaten</Card.Header>
      <ListGroup variant="flush">
        {content.vereinsdaten && (
          <>
            <ListGroup.Item><strong>Gründungsjahr:</strong> {content.vereinsdaten.grndungsjahr}</ListGroup.Item>
            <ListGroup.Item><strong>Mitglieder:</strong> {content.vereinsdaten.mitglieder}</ListGroup.Item>
            <ListGroup.Item><strong>Sportarten:</strong> {content.vereinsdaten.sportarten}</ListGroup.Item>
            <ListGroup.Item><strong>Vereinsfarben:</strong> {content.vereinsdaten.vereinsfarben}</ListGroup.Item>
            <ListGroup.Item><strong>Vereinsregisternummer:</strong> {content.vereinsdaten.vereinsregisternummer}</ListGroup.Item>
          </>
        )}
      </ListGroup>
    </Card>
  );

  return (
    <Container>
      <h2 className="verein-heading mb-4">Über unseren Verein</h2>
      
      <section className="mb-5">
        <Row>
          <Col lg={8}>
            {content.geschichte?.aboutus && (
              <div className="text-start">
                {documentToReactComponents(content.geschichte.aboutus)}
              </div>
            )}
          </Col>
          <Col lg={4} className="mt-4 mt-lg-0">
            {renderVereinsdaten()}
          </Col>
        </Row>
      </section>

      <section className="mb-5">
        <h3 className="mb-4">Vorstand</h3>
        <Row>
          {content.vorstand.map(renderVorstandCard)}
        </Row>
      </section>
    </Container>
  );
};

export default Verein;
